import { connect } from "react-redux";
import React from "react";
import { useNavigate } from 'react-router-dom';

import { parseTime } from "../../helpers/parseTime";
import { CLEAR_OPTION} from "../../constants/actionTypes";

import Amrap from "./Amrap/Amrap";
import Emom from "./Emom/Emom";
import  { useEffect } from "react";

import { trackGAEvent } from "../ga4";

function FullBodyFinish(props) {
	useEffect(() => {
		document.title = 'Finish cardio workout';
	}, []);

	const linkToNextPage = `/`;
	const navigate = useNavigate();	

	let restTime = props.options.time - props.timer;

 	function goNext(){
		props.onClearOptions();
        navigate(linkToNextPage);
    }

	function handleFinish(event) {
        trackGAEvent("Full body", "Button Click", "Finish Full body exercises");
    }


	return (
		<>
			<div className="Exercises-body">
				<h1 className="Exercises-bigtitle">Congrats!</h1>
				<h2 className='Exercises-title'>You are done with your workout!</h2>
				<div className='Exercises-result'>
					<div className="Exercises-result-subtitle">Time</div>
					<div className="Exercises-result-time">{Object.values(parseTime(restTime)).join(":")}</div>
				</div>
				<h3 className="Exercises-title_fullbody">{props.exercise.title}</h3>
				<p className="Exercises-title_description">{props.exercise.type}</p>
				<div className="Exercises-container">
					{props.exercise.sets.map((set, index) => {
						if(set.set_type === "EMOM"){
							return 	<div className="Workout__superset" key={index}>
										<div className="Workout__superset-title">
											Superset
											{(set["sets-count"] > 1) &&
											<> &bull; {set["sets-count"]} Rounds</>}
										</div>
										<Emom set={set}/>
									</div>
						}else{
							return 	<div className="Workout__superset" key={index}>
										<div className="Workout__superset-title">
											Superset
											{(set["sets-count"] > 1) &&
											<> &bull; {set["sets-count"]} Rounds</>}
										</div>
										<Amrap set={set}/>
									</div> 	
						}
						
					})	
					}
				</div>
				<button className="go-Next" onClick={()=>{goNext();handleFinish()}}>Start New Workout</button>

			</div>
			
		</>
	);
}
const mapStateToProps = state =>{
    return {
        exercise: state.exercise.selectedFullBodyExercises,
        timer: state.exercise.timer,
		options: state.option.options
    }    
}

const mapDispatchToProps = dispatch => ({
	onClearOptions: () => {
		dispatch({
			type: CLEAR_OPTION
		})
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(FullBodyFinish);
