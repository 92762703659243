import arrow from "./../../img/back.svg";
import check from "./../../img/check.svg";
import next from "./../../img/next.svg";
import pause from "./../../img/small-pause.svg";
import resume from "./../../img/small-resume.svg";

import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";

import { SET_STATUS} from "../../constants/actionTypes";
import useDidMountEffect from "../../helpers/useDidMountEffect";
import { parseTime } from "../../helpers/parseTime";
import Video from "../Video/Video";

function ItemNoRest(props) {

	let {id} = useParams();

	useEffect(() => {
        document.title = props.exercise.title;
    },[]);
	
	const exercise = props.exercise;
	const linkToPreviousPage = `/muscle-list`;
	const linkToNextExercise = `/muscle-list/${props.nextExercise}`;
	const linkToInfo = `/muscle-list/${id}/info`;

	const navigate = useNavigate();	

    function goBack(){
		clearInterval(restTimerId);
        navigate(linkToPreviousPage);
    }	
	function goInfo(){
		navigate(linkToInfo);
	}
	const [stateRest, setStateRest] = useState({
		timer: exercise.status.reps[exercise.status.current].timer,
		printTimer: parseTime(exercise.status.reps[exercise.status.current].timer),
		isStop: true
	});	
	const [restTimerId, setRestTimerId] = useState(null);

	const [status, setStatus] = useState(exercise.status);

	function startRestTimer(){
		setRestTimerId(setInterval(() => {
			let newTimer = --stateRest.timer;
			if(newTimer >= 0){
				setStateRest({
					...stateRest,
					timer: newTimer,
					printTimer: parseTime(newTimer),
					isStop: false
				})
				setStatus({
					...status,
					isRest: true,
					reps: status.reps.map((item, index) => {
						if(status.current === index){
							item.currentTimer = newTimer;
							item.activeRest = true;
						}
						return item;
					})
				})
			} else {
				endRest();
			}
		}, 1000))
    }

	function goRest() {
		setStatus({
			...status,
			isRest: true,
			reps: status.reps.map((item, index) => {
				if(status.current === index){
					item.activeRest = true;
				}
				return item;
			})
		})
		setStateRest({
			...stateRest,
			timer: status.reps[status.current].timer,
			printTimer: parseTime(status.reps[status.current].timer),
			isStop: false
		})
		startRestTimer();
	}

	function stopRest(){
		clearInterval(restTimerId);
		setStateRest({
			...stateRest,
			isStop: true
		})
	}
	
	function resumeRest(){
		setStateRest({
			...stateRest,
			isStop: false
		});
		startRestTimer();
	}	

	function endRest(){
		clearInterval(restTimerId);
		let current = status.current;
		if(current + 1 < status.reps.length){
			setStatus(
				{
				...status,
				isRest: false,
				current: current+1,
				reps: status.reps.map((item, index) => {
					if(current + 1 === index){
						item.activeRep = true;
					}
					return item;
				})
			});
			setStateRest({
				...stateRest,
				timer: status.reps[current+1].timer,
				printTimer: parseTime(status.reps[current+1].timer),
				isStop: true
			})
		} else {
			setStatus(
				{
				...status,
				isRest: false,
				current: current,
				isComplete: true,
				reps: status.reps.map((item, index) => {
					if(current === index){
						item.activeRest = true;
					}
					return item;
				})
			});
			setStateRest({
				...stateRest,
				isStop: true
			})
		}
	}

	function goNext(){
		clearInterval(restTimerId);
        navigate(linkToNextExercise);
	}

	useDidMountEffect(() => {
		props.onSetStatus(id, status);
	}, [status.isRest]);
	
	useDidMountEffect(() => {
		props.onSetStatus(id, status);
		if(!props.isComplete || !status.isComplete)
			navigate(linkToNextExercise);
	}, [status.current, status.isComplete]);
	return (
		<>
			<img src={arrow} alt="arrow" className="go-Back" onClick={goBack}/>
			{exercise.video && exercise.video !== "" && <Video src={exercise.video} title={exercise.title}/>}
			

			<div className="Item-body">
				<div className="Item-top">
					<h2 className="Item-title">{exercise.title}</h2>
				</div>
				<p className="Item-tempo">{exercise.tempo.tempo_title}</p>
				<p className="Item-description" onClick={goInfo}>Instruction <img src={next} alt="next"/></p>
				<div>
				{status.reps.map((set, index) => {
					return(
						<div key={index}  className="Item-process">
							
							{(set.activeRep)
							? <>
								<div className="Item-step active">
									{(set.activeRest)
									? <img src={check} alt="check"/>
									: <span>{index + 1}</span>
									}
								</div>
								<div className="Item-reps active">{exercise.reps_sets.reps} {exercise.reps_sets.reps == 1 ? <>Rep</> : <>Reps</>}</div>
							</>
							:<>
								<div className="Item-step">
									<span>{index + 1}</span>
								</div>	
								<div className="Item-reps">{exercise.reps_sets.reps} {exercise.reps_sets.reps == 1 ? <>Rep</> : <>Reps</>}</div>
							</>
							}
							<div className="Item-Zero"></div>
							<div></div>
							{(set.activeRest && index == status.current && !status.isComplete)
							?<>
								<div className="Item-rest active"></div>
								<div className="Item-rest-time active">
									<div className="Item-rest-time-title">Rest</div>
									<div className="Item-rest-time-duration">{Object.values(parseTime(set.timer)).join(":")}</div>
								</div>
							</>
							:<>
								<div className="Item-rest"></div>
								<div className="Item-rest-time">
									<div className="Item-rest-time-title">Rest</div>
									<div className="Item-rest-time-duration">{Object.values(parseTime(set.timer)).join(":")}</div>
								</div>
							</>
							}
							
							{ (index+1 !== status.reps.length) 
							? <>
								<div className="Item-Zero"></div>
								<div></div>
								</>
							: <div></div>
							}
							
						</div>
					)					
				})
                }
				</div>
			</div>
			{(status.isRest === false)
			? ((status.isComplete) 
				? <button className="go-Next" onClick={goNext}>Done</button> 
				: <button className="go-Next" onClick={goRest}>Log Set + Rest</button>
			)
			: 
			<>
				<div className="Cardio-rest-timer">
					<div className="Cardio-rest-timer-top">
						<div className="Cardio-rest-timer-top-title">Rest</div>
						<div className="Cardio-rest-timer-top-timer">
							{stateRest.printTimer.minutes}:{stateRest.printTimer.seconds}
						</div>
					</div>
					<div className="Cardio-rest-timer-buttons">
							{(stateRest.isStop)
							? <button className="Cardio-rest-timer-button Cardio-rest-timer-pause" onClick={resumeRest}>
								<img src={resume} alt="resume"/> Resume
							</button>
							: <button className="Cardio-rest-timer-button Cardio-rest-timer-pause" onClick={stopRest}>
								<img src={pause} alt="pause"/> Pause
							</button>
							}
						<button className="Cardio-rest-timer-button Cardio-rest-timer-end" onClick={endRest}>
							End Rest
						</button>
					</div>
				</div>			
			</>
			}
		</>
	);
}

const mapStateToProps = state =>{
    return {
        exercises: state.exercise.selectedMuscleExercises
    }    
}

const mapDispatchToProps = dispatch => ({
    onSetStatus: (id, status) => {
        dispatch({
            type: SET_STATUS, id, status
        })
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemNoRest);
