import img from './../../img/focus.png'
import arrow from './../../img/go_back.svg'

import {connect} from 'react-redux';
import { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { trackGAEvent } from "../ga4";
import { CARDIO, FULL_BODY, MUSCLE, SET_FOCUS, SET_EXERCISES } from '../../constants/actionTypes';

function OptionFocus(props){

    const [state, setState] = useState({
        disable: true,
        focusList: [
            {label: "Chest",checked: false,value: 'Chest',id: "chest"},
            {label: "Back", checked: false,value: 'Back', id: "back"},
            {label: "Shoulders",checked: false,value: 'Shoulders',id: "shoulders"},
            {label: "Biceps",checked: false,value: 'Biceps',id: "biceps"},
            {label: "Triceps",checked: false,value: 'Triceps',id: "triceps"},
            {label: "Legs",checked: false,value: 'Legs',id: "legs"},
            {label: "Glutes",checked: false,value: 'Glutes',id: "glutes"}
        ],
        typeList: [
            {label: "Full Body / HIIT",checked: false,value: FULL_BODY,id: "body"},
            {label: "Cardio",checked: false,value: CARDIO,id: "cardio"},        
        ]
    });
    const linkToPreviousPage = `/time`;
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Focus page';
        if(props.exerciseType === MUSCLE && state.focusList.some((item => props.exerciseValue.includes(item.value)))){
            const newFocusList = state.focusList.map(item => {
                    if(props.exerciseValue.includes(item.value)){
                        item.checked = true;
                    }
                    return item;
                })
            setState({...state, focusList: newFocusList, disable: false});
        } else if(state.typeList.some(item => item.value === props.exerciseType)){
            const newTypeList = state.typeList.map(item => {
                    if(item.value === props.exerciseType){
                        item.checked = true;
                    }
                    return item;
                })
            setState({...state, typeList: newTypeList, disable: false});
        }
    },[]);

    function goBack(){
        navigate(linkToPreviousPage);
    }

    function goNext(){
        switch(props.exerciseType){
            case MUSCLE:
                navigate(`/goal`); break;
            case CARDIO:
                props.onSelectExercise(props.options); 
                navigate(`/cardio`); break;
            case FULL_BODY:
                props.onSelectExercise(props.options); 
                navigate(`/body`); break;
            default:
                navigate(`/goal`);
        }
    }

    function focusChange(e){
        const currentFocuses = state.focusList.filter(item => item.checked).map(item => item.value);
        let currentValue = e.target.value;
        if(currentFocuses.includes(currentValue)){
            const newFocusList = state.focusList.map(item => {
                if(item.value === currentValue){
                    item.checked = !item.checked;
                }
                return item;
            })
            const newTypelist = state.typeList.map(item => {
                item.checked = false;
                return item;
            })    
            const focuses = newFocusList.filter(item => item.checked).map(item => item.value);
            if(focuses.length){
                setState({...state, focusList: newFocusList, typeList: newTypelist, disable: false})
            } else {
                setState({...state, focusList: newFocusList, typeList: newTypelist, disable: true})
            }
            props.onChangeFocus(MUSCLE, focuses);
        } else if(currentFocuses.length < 2){
            const newFocusList = state.focusList.map(item => {
                if(item.value === currentValue){
                    item.checked = true;
                }
                return item;
            })
            const newTypelist = state.typeList.map(item => {
                item.checked = false;
                return item;
            })
            setState({...state, focusList: newFocusList, typeList: newTypelist})
            const focuses = newFocusList.filter(item => item.checked).map(item => item.value);
            if(focuses.length){
                setState({...state, focusList: newFocusList, typeList: newTypelist, disable: false})
            } else {
                setState({...state, focusList: newFocusList, typeList: newTypelist, disable: true})
            }
            props.onChangeFocus(MUSCLE, focuses);            
        }
    }

    function typeChange(e){
        const newFocusList = state.focusList.map(item => {
                item.checked = false;
                return item;
            })
        let type = MUSCLE;
        const newTypelist = state.typeList.map(item => {
                if(item.value === e.target.value){
                    item.checked = true;
                    type = item.value;
                } else {
                    item.checked = false;
                }
                return item;
            })
        setState({...state, focusList: newFocusList, typeList: newTypelist, disable: false})
        const focuses = newFocusList.filter(item => item.checked).map(item => item.value);
        props.onChangeFocus(type, focuses);
    }

    function handleSubmit(event) {
        trackGAEvent("Next Page", "Button Click", "Focus selected");
    }

    return (
        <>
        <img src={arrow} alt='arrow' onClick={goBack} className='go-Back'/>
        <div className="Focus-body">
            <div className="Focus-wrapper">  
                <h2 className="Focus-title">
                    What should<br></br> we <span className="Focus-span span">focus</span> on today ?
                </h2>
                <h3 className="Focus-small_title">Pick up to 2</h3>
                <div className='Focus-selection'>
                    {
                    state.focusList.map((item, index) => {
                        return (
                            <div className='Focus-select' key={index}>
                                <input  type='checkbox' name="focus[]" id={item.id} className="Focus-input" 
                                data-id={index} checked={item.checked} value={item.value} onChange={focusChange}/>
                                <label htmlFor={item.id} className="Focus-label">{item.label}</label>
                            </div>
                        )
                    })
                    }    
                </div>
                <div className='Focus-footer'>
                    <h3 className="Focus-small_title">Or select only one</h3>
                    <div className="Focus-selection">
                        {
                        state.typeList.map((item, index) => {
                            return (
                                <div className='Focus-select' key={index}>
                                    <input type='radio' name="types" id={item.id} className="Focus-input"
                                    value={item.value} onChange={typeChange} checked={item.checked}/>
                                    <label htmlFor={item.id} className="Focus-label">{item.label}</label> 
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
            <div className="Focus-image">
               <img src={img} alt='time' className='img'/>         
            </div>
            
            <button className='go-Next' onClick={()=>{goNext();handleSubmit()}} disabled={state.disable}>Next</button>
        </div>
        </>
    )
}
const mapStateToProps = state =>{
    return {
        exerciseType: state.option.options.exerciseType,
        exerciseValue: state.option.options.exerciseValue,
        options: state.option.options
    }    
}

const mapDispatchToProps = dispatch => ({
    onChangeFocus: (exerciseType, exerciseValue) => {
        dispatch({
            type: SET_FOCUS, exerciseType, exerciseValue
        })
    },
    onSelectExercise: (options) => {
        dispatch({
            type: SET_EXERCISES, options
        })
    }
});

  
export default connect(mapStateToProps, mapDispatchToProps )(OptionFocus);