import arrow from "./../../img/go_back.svg";

import pause from "./../../img/pause.svg";
import start from "./../../img/resume.svg";
import check from "./../../img/check.svg";
import statusImage from "./../../img/status.svg";
import videoArrow from "./../../img/video-arrow.svg";

import { connect } from "react-redux";
import { Link} from "react-router-dom";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { trackGAEvent } from "../ga4";

function MuscleList(props) {
	useEffect(() => {
        document.title = 'Muscle exercises list';
    }, []);

	const linkToPreviousPage = `/muscle-start`;
	const linkToNextPage = `/muscle-finish`;
	const navigate = useNavigate();	

    function goBack(){
        navigate(linkToPreviousPage);
    }
 	function goNext(){
        navigate(linkToNextPage);
    }

	function handleFinish(event) {
        trackGAEvent("Muscle", "Button Click", "Finish Muscle exercises");
    }
	
	return (
		<>
			<img src={arrow} alt="arrow" onClick={goBack} className="go-Back"/>
			<div className="Exercises-body">
				<h3 className="Exercises-title_small">Exercises</h3>
				<div className="Exercises-container">
					{props.exercises.map(function (list, key) {
						return (
							<div className="Workout__superset" key={key}>
								<div className="Workout__superset-title">
									Superset {key+1}
								</div>
								<div className="Workout__superset-list">
									{list.map(function (exercise, index){
										if(exercise.status.isComplete){
											return (
											<div className="Exercises-item" key={index}>
												<Link to={`${exercise.id}`}>
													<img src={exercise.image} alt="video" className="Exercises-image"/>
													<div className="Exercises-right">
														<p className="Exercises-name">{exercise.title}</p>
														<p className="Exercises-complete">{exercise?.reps_sets.sets}/{exercise?.reps_sets.sets} logged</p>
													</div>
													<div className="Exercises-state">
														<div className="Exercises-status">
															<img src={check} alt="check" />
														</div>
													</div>
												</Link>
											</div>
										);
										} else if(exercise.status.reps[0].activeRest === false){
											return (
												<div className="Exercises-item" key={index}>
													<Link to={`${exercise.id}`}>
														<img src={exercise.image} alt="video" className="Exercises-image"/>
														<div className="Exercises-right">
															<p className="Exercises-name">{exercise.title}</p>
															<p className="Exercises-exercise">
																<span>{exercise?.reps_sets.sets + " sets"}</span> . <span>{exercise?.reps_sets.reps + " reps"}</span>
															</p>
															<p className="Exercises-tempo">{exercise?.tempo.tempo_title}</p>
														</div>
														<div className="Exercises-state">
															<div className="Exercises-status">
																<img src={videoArrow} alt="video-arrow" className="Exercises-arrow"/>
															</div>
														</div>	
													</Link>
												</div>
											);
										} else {
											let complete = exercise.status.reps.filter(item => item.activeRest === true).length;
											let all = exercise.status.reps.length;
											let percent = ~~(complete/all*100);
											return (
												<div className="Exercises-item" key={index}>
													<Link to={`${exercise.id}`}>
														<img src={exercise.image} alt="video" className="Exercises-image"/>
														<div className="Exercises-right">
															<p className="Exercises-name">{exercise.title}</p>
															<p className="Exercises-complete">{complete}/{all} logged</p>
														</div>
														<div className="Exercises-state">
															<div className="Exercises-status">
																<div className="Exercises-progress" style={{"--p":percent}}>
																	
																</div>
																<img src={statusImage} alt="status" />
															</div>
														</div>
													</Link>
												</div>
											);
										}
									})}
							</div>
						</div>	
						);
					})}
				</div>
				<button className="go-Next" onClick={()=>{goNext();handleFinish();}}>Finish Workout</button>
			</div>
		</>
	);
}
const mapStateToProps = state =>{
    return {
        exercises: state.exercise.selectedMuscleExercises,
		exerciseValue: state.option.options.exerciseValue
    }    
}

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(MuscleList);
