import img from './../../img/home.png'
import logo from './../../img/logo.png'
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { trackGAEvent } from "../ga4";
function Home(props){
    useEffect(() => {
        document.title = 'Home';
    }, []);

    const linkToNextPage = `/time`;
    const navigate = useNavigate();

    function handleSubmit(event) {
        trackGAEvent("Next Page", "Button Click", "Let's Go");
    }

    function goNext(){  
        navigate(linkToNextPage);
    }

    return(
        <>
            <div className='StartPage-body'>
                <img className='StartPage-logo img' src={logo} alt='logo' />
                <img className='StartPage-img img' src={img} alt='start'/>
                <div className='Startpage-info'>
                    <h1 className='StartPage-title'>The Menu</h1>
                    <p className='StartPage-text'>Let's build your workout for today</p>
                </div>
            </div>
            <button className='go-Next' onClick={()=>{goNext();handleSubmit()}}>Let's Go</button>
        </>
    )
}

export default Home;