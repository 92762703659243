import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams} from 'react-router-dom';
import Video from "../Video/Video";
import  { useEffect } from "react";
import { trackGAEvent } from "../ga4";
function FullBodyItemInfo(props) {
	useEffect(() => {
		document.title = props.exercise.name;
	}, []);

	const navigate = useNavigate();	

	let {setId,elementId} = useParams();
	const set = props.exercise.sets.find(item => item.id == setId);
	const exercise = set.exercises.find(item => item.id == elementId);

    const linkToPreviousPage = `/body/${setId}/${elementId}`;
    function goBack(){
        navigate(linkToPreviousPage);
    }
	function handleBack(event) {
        trackGAEvent("Muscle", "Button Click", "Back button");
    }
	return (
		<>
			{exercise.video !== "" && <Video src={exercise.video} title={exercise.name}/>}
			<div className="Cardio-body">
				{exercise.video !== "" 
				? 
				<div className="Cardio-top">
					<p className="Cardio-tempo">Instruction</p>
					<div className="Cardio-top-title">
						<h2 className="Cardio-title">
							{exercise.name}
						</h2>
					</div>				
				</div>
				:
				<div className="Cardio-top Cardio-top_no-video">
					<p className="Cardio-tempo">Instruction</p>
					<div className="Cardio-top-title">
						<h2 className="Cardio-title">
							{exercise.name}
						</h2>
					</div>				
				</div>					
				}
				<div className="Cardio-equipment Cardio-equipment_item">
					<div className="Cardio-equipment_text">
						{exercise.description}
					</div>
				</div>
			</div>
			<button className="go-Next" onClick={()=>{goBack();handleBack();}}>Back</button>
		</>
	);
}

const mapStateToProps = state =>{
    return {
        exercise: state.exercise.selectedFullBodyExercises
    }    
}

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(FullBodyItemInfo);
