import check from "./../../img/check.svg";
import statusImage from "./../../img/status.svg";

import { connect } from "react-redux";
import React from "react";
import { useNavigate } from 'react-router-dom';

import { CLEAR_OPTION } from "../../constants/actionTypes";
import { useEffect } from "react";
import { trackGAEvent } from "../ga4";

function MuscleFinish(props) {

	useEffect(() => {
        document.title = 'Finish muscle workout';
    }, []);
	const linkToPreviousPage = `/`;
	const navigate = useNavigate();	

    function goNext(){
		props.onClearOptions();
        navigate(linkToPreviousPage);
    }

	function handleStart(event) {
        trackGAEvent("Muscle", "Button Click", "New Muscle exercises");
    }
	
	return (
		<>
			<div className="Exercises-body">
				<h1 className="Exercises-bigtitle">Congrats!</h1>
				<h2 className='Exercises-title'>You are done with your workout!</h2>
				<h3 className="Exercises-title_small">Exercises</h3>
				<div className="Exercises-container">
					{props.exercises.map(function (list, key) {
						return (
							<div className="Workout__superset" key={key}>
								<div className="Workout__superset-title">
									Superset {key+1}
								</div>
								<div className="Workout__superset-list">
									{list.map(function (exercise, index){
										if(exercise.status.isComplete){
											return (
											<div className="Exercises-item" key={index}>
												<div className="Exercises-no_link">
													<img src={exercise.image} alt="video" className="Exercises-image"/>
													<div className="Exercises-right">
														<p className="Exercises-name">{exercise.title}</p>
														<p className="Exercises-complete">{exercise?.reps_sets.sets}/{exercise?.reps_sets.sets} logged</p>
													</div>
													<div className="Exercises-state">
														<div className="Exercises-status">
															<img src={check} alt="check" />
														</div>										
													</div>
												</div>
											</div>
										);
										} else {
											let complete = exercise.status.reps.filter(item => item.activeRest === true).length;
											let all = exercise.status.reps.length;
											let percent = ~~(complete/all*100);
											return (
												<div className="Exercises-item" key={index}>
													<div className="Exercises-no_link">
														<img src={exercise.image} alt="video" className="Exercises-image"/>
														<div className="Exercises-right">
															<p className="Exercises-name">{exercise.title}</p>
															<p className="Exercises-complete">{complete}/{all} logged</p>
														</div>
														<div className="Exercises-state">
															<div className="Exercises-status">
																<div className="Exercises-progress" style={{"--p":percent}}>
																	
																</div>
																<img src={statusImage} alt="status" />
															</div>
														</div>
													</div>
												</div>
											);
										}
									})}
							</div>
						</div>	
						);
					})}
				</div>
				<button className="go-Next" onClick={()=>{goNext();handleStart();}}>Start New Workout</button>
			</div>
		</>
	);
}
const mapStateToProps = state =>{
    return {
        exercises: state.exercise.selectedMuscleExercises,
        timer: state.exercise.timer,
		options: state.option.options
    }    
}

const mapDispatchToProps = dispatch => ({
	onClearOptions: () => {
		dispatch({
			type: CLEAR_OPTION
		})
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(MuscleFinish);
